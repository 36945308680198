











import Vue from "vue";
import { getErrors } from "@/validation";

export default Vue.extend({
  name: "FieldError",
  props: ["input", "label", "errorDict"],
  methods: { getErrors }
});
