













import Vue, { PropType } from "vue";
import FieldError from "@/components/form/FieldError.vue";
import { getErrors } from "@/validation";

export default Vue.extend({
  name: "FieldGroup",
  props: {
    input: { type: Object },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },

    purpleLabel: {
      type: Boolean,
      default: false
    },
    errorDict: {
      type: Object as PropType<{ [key: string]: (field: string) => string }>
    }
  },
  data() {
    return {
      labelClass: {
        required: this.input.required !== undefined,
        "purple-uppercase-typo": this.purpleLabel
      }
    };
  },
  components: { FieldError },
  methods: { getErrors }
});
