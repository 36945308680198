export const isRequired = (val?: string | null) =>
  val !== null && val !== undefined && val !== "";

export const isValidURL = (url: string) => {
  const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/gi;
  return urlRegex.test(url);
};

export const isValidNumber = (value: string | number) => {
  return /[\d.,]/.test(`${value}`);
};

export const phone = (value: string) => {
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return phoneRegex.test(value);
};

const errorDictionary = {
  required: (field: string) => `${field} is required`,
  email: (field: string) => `${field} is not a valid email`,
  phone: (field: string) => `${field} is not a valid phone number`,
  vueTelValid: (field: string) => `${field} is not a valid phone number`
};

export const getErrors = (
  field: { [key: string]: unknown },
  fieldName: string,
  errorDict?: { [key: string]: (field: string) => string }
) => {
  return Object.entries({ ...errorDictionary, ...errorDict })
    .map(([key, val]) => (field[key] === false ? val(fieldName) : null))
    .filter(v => v !== null);
};
